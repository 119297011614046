import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { BASE_FUN_URL, BASE_URL, generateFormattedId } from "../../constants";
import { useNavigate, useParams } from "react-router-dom";
import { Input, Table, Modal } from "antd";
import { toast } from "react-toastify";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import AppContext from "../../AppContext";
import { useTranslation } from "react-i18next";
const IntegrationList = () => {
  const { t } = useTranslation();
  const { type } = useParams();
  const FilterInput = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        placeholder="Search..."
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => confirm()}
        style={{ width: 188, marginBottom: 8, display: "block" }}
      />
      <div>
        <button
          type="primary"
          onClick={() => confirm()}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Filter
        </button>
        <button
          onClick={() => clearFilters()}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </button>
      </div>
    </div>
  );
  const [isPublishModalVisible, setIsPublishModalVisible] = useState(false);

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    const searchText = selectedKeys[0];
    if (searchText && searchText.trim() !== "") {
      const filtered = dataList.filter((item) =>
        item.name?.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(dataList); // If search text is empty, show all data
    }
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setFilteredData(dataList); // Revert to the original data set
  };
  const showPublishModal = () => {
    setIsPublishModalVisible(true);
  };

  const handleCancelPublish = () => {
    setIsPublishModalVisible(false);
  };

  const handleConfirmPublish = async () => {
    try {
      const res = await axios.post(`${BASE_FUN_URL}/api/Restart`);
      if (res.status === 200) {
        toast.success("Cache reset successfully!");
      } else {
        toast.error("Failed to reset cache");
      }
    } catch (error) {
      toast.error("An error occurred while resetting cache");
    }
    setIsPublishModalVisible(false);
  };
  const columns = [
    {
      width: 100,
      title: "",
      dataIndex: "id",
      render: (id) => (
        <button
          className="bg-blue-500 text-white px-3 py-1 rounded-md"
          onClick={() => navigate(`/integration/${id}`)}
        >
          {t("buttons.edit")}
        </button>
      ),
    },
    {
      title: t("form_labels.app_id"),
      dataIndex: "readableId",
      // render: (readableId, __, index) => ,
    },
    {
      title: t("form_labels.integration_name"),
      dataIndex: "name",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <FilterInput
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onFilter: (value, record) =>
        record.name.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: t("form_labels.description"),
      dataIndex: "description",
      // width: 150,
      render: (description) => (
        <div title={description}>{truncateDescription(description, 30)}</div>
      ),
    },
    {
      title: t("form_labels.company"),
      dataIndex: "company",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <FilterInput
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onFilter: (value, record) =>
        record.company?.toLowerCase().includes(value.toLowerCase()),
    },
    {
      width: 100,
      title: t("form_labels.status"),
      dataIndex: "status",
      render: (status) => (
        <div
          className={`${
            status ? "bg-green-500" : "bg-red-500"
          } rounded-md px-2 py-1 w-fit text-xs text-white font-medium `}
          //   onClick={() => navigate(`/appForm/${id}`)}
        >
          {status ? "Active" : "InActive"}
        </div>
      ),
    },
    {
      title: t("form_labels.source"),
      dataIndex: "source",
      render: (source) => source?.name,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <FilterInput
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onFilter: (value, record) =>
        record.source?.name?.toLowerCase()?.includes(value?.toLowerCase()),
    },
    {
      width: 250,
      title: t("form_labels.destinations"),
      dataIndex: "destinations",
      render: (destinations) => (
        <div className=" text-xs text-white font-medium">
          {destinations.map((destination, index) => (
            <div
              className="bg-gray-500 mb-1 rounded-md px-2 py-1 w-fit ml-1"
              key={index}
            >
              {destination.name} - {destination.connectivity?.type}
            </div>
          ))}
        </div>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <FilterInput
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onFilter: (value, record) =>
        record.destinations.some((destination) =>
          destination.name.toLowerCase().includes(value?.toLowerCase())
        ),
    },
  ];
  const truncateDescription = (description, maxLength) => {
    if (description?.length > maxLength) {
      return description.substring(0, maxLength) + "...";
    } else {
      return description;
    }
  };
  const navigate = useNavigate();
  const [dataList, setDataList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { authCtx } = useContext(AppContext);
  const loadData = async () => {
    setLoading(true);
    const res = await axios.get(
      `${BASE_URL}/api/IntegrationItems?companyId=${authCtx.profile.companyId}`
    );
    if ((res.status = 200)) {
      const data = res.data;
      let finalData = data;
      if (type) {
        // Filter the data to get only those items where any destination has connectivity type 'api'
        let filteredData = data?.filter((item) =>
          item.destinations.some((dest) => dest?.connectivity?.type === type)
        );
        finalData = filteredData;
      }
      setDataList(finalData); // Set the filtered list to the state
      setFilteredData(finalData); // Initialize filteredData with the filtered list
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="mx-auto px-4 sm:px-6 lg:px-8 mt-6">
      <div className="md:flex justify-between sm:items-center">
        <div className="md:flex w-full gap-4">
          <h1 className="md:text-xl  w-fit font-semibold leading-6 text-gray-900">
            {t("pages.integrations")}
          </h1>
          <Input.Search
            className="w-2/5"
            placeholder={t("pages.integrations_search_label")}
            onSearch={handleSearch}
          />
        </div>

        <div className="mt-4 flex gap-2 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            className="flex items-center justify-center w-32 gap-2 rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
            onClick={() => navigate("/integration/new")}
          >
            <PlusOutlined /> {t("buttons.integration")}
          </button>
          <button
            type="button"
            className="flex items-center justify-center w-32 gap-2 rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
            onClick={showPublishModal}
          >
            <UploadOutlined />
            {t("buttons.publish")}
          </button>
        </div>
      </div>
      <div className="flex lg:my-3">
        <Table
          className="border w-full"
          columns={columns}
          dataSource={filteredData} // Use filteredData instead of dataList
          rowKey="id"
          loading={loading}
          pagination
          scroll={{
            y: "62vh",
          }}
        />
      </div>
      <Modal
        title="Confirm Publish"
        visible={isPublishModalVisible}
        onOk={handleConfirmPublish}
        onCancel={handleCancelPublish}
      >
        <p>Are you sure you want to publish?</p>
      </Modal>
    </div>
  );
};

export default IntegrationList;
