import { PublicClientApplication } from "@azure/msal-browser";
// export const BASE_URL = "https://integration-platform-api.azurewebsites.net";
// export const BASE_URL = "https://hermes-ip-test-api.azurewebsites.net";
export const BASE_URL = "https://hermes-apiprod.azurewebsites.net";
// export const BASE_FUN_URL = "https://ip-dev-receiver.azurewebsites.net";
// export const BASE_FUN_URL = "https://ip-test-receiver.azurewebsites.net";
export const BASE_FUN_URL = "https://hermes-reciever.azurewebsites.net";
// export const BASE_URL = "https://localhost:7030";
export const CleanKey = (key) => key.trim().replace(/\s+/g, "_").toLowerCase();
export const Client_id =
  "875838615965-k4ui5vc23fdtgjaa5nvg4er82h2a3pe1.apps.googleusercontent.com";
export const AppIconPath =
  "https://gddatastorage.blob.core.windows.net/images/appIcons/";
// export const AppIconPath =
//   "https://hermesproddb.blob.core.windows.net/images/appIcons/";
export const Roles = [
  { label: "Super-Admin", value: 100 },
  { label: "Admin", value: 200 },
  { label: "Operator", value: 300 },
];
export const Country = [
  { label: "Afghanistan", code: "AF", value: 100 },
  { label: "Albania", code: "AL", value: 200 },
  { label: "Algeria", code: "DZ", value: 300 },
  { label: "Andorra", code: "AD", value: 400 },
  { label: "Angola", code: "AO", value: 500 },
  { label: "Argentina", code: "AR", value: 600 },
  { label: "Armenia", code: "AM", value: 700 },
  { label: "Australia", code: "AU", value: 800 },
  { label: "Austria", code: "AT", value: 900 },
  { label: "Azerbaijan", code: "AZ", value: 1000 },
  { label: "Bangladesh", code: "BD", value: 1100 },
  { label: "Belarus", code: "BY", value: 1200 },
  { label: "Belgium", code: "BE", value: 1300 },
  { label: "Bolivia", code: "BO", value: 1400 },
  { label: "Bosnia and Herzegovina", code: "BA", value: 1500 },
  { label: "Brazil", code: "BR", value: 1600 },
  { label: "Bulgaria", code: "BG", value: 1700 },
  { label: "Canada", code: "CA", value: 1800 },
  { label: "Chile", code: "CL", value: 1900 },
  { label: "China", code: "CN", value: 2000 },
  { label: "Colombia", code: "CO", value: 2100 },
  { label: "Costa Rica", code: "CR", value: 2200 },
  { label: "Côte d'Ivoire", code: "CI", value: 2300 },
  { label: "Croatia", code: "HR", value: 2400 },
  { label: "Cuba", code: "CU", value: 2500 },
  { label: "Cyprus", code: "CY", value: 2600 },
  { label: "Czech Republic", code: "CZ", value: 2700 },
  { label: "Denmark", code: "DK", value: 2800 },
  { label: "Ecuador", code: "EC", value: 2900 },
  { label: "Egypt", code: "EG", value: 3000 },
  { label: "El Salvador", code: "SV", value: 3100 },
  { label: "Estonia", code: "EE", value: 3200 },
  { label: "Ethiopia", code: "ET", value: 3300 },
  { label: "Finland", code: "FI", value: 3400 },
  { label: "France", code: "FR", value: 3500 },
  { label: "Germany", code: "DE", value: 3600 },
  { label: "Ghana", code: "GH", value: 3700 },
  { label: "Greece", code: "GR", value: 3800 },
  { label: "Guatemala", code: "GT", value: 3900 },
  { label: "Honduras", code: "HN", value: 4000 },
  { label: "Hungary", code: "HU", value: 4100 },
  { label: "Iceland", code: "IS", value: 4200 },
  { label: "India", code: "IN", value: 4300 },
  { label: "Indonesia", code: "ID", value: 4400 },
  { label: "Iran", code: "IR", value: 4500 },
  { label: "Iraq", code: "IQ", value: 4600 },
  { label: "Ireland", code: "IE", value: 4700 },
  { label: "Israel", code: "IL", value: 4800 },
  { label: "Italy", code: "IT", value: 4900 },
  { label: "Jamaica", code: "JM", value: 5000 },
  { label: "Japan", code: "JP", value: 5100 },
  { label: "Jordan", code: "JO", value: 5200 },
  { label: "Kazakhstan", code: "KZ", value: 5300 },
  { label: "Kenya", code: "KE", value: 5400 },
  { label: "South Korea", code: "KR", value: 5500 },
  { label: "Kosovo", code: "XK", value: 5600 },
  { label: "Kuwait", code: "KW", value: 5700 },
  { label: "Kyrgyzstan", code: "KG", value: 5800 },
  { label: "Laos", code: "LA", value: 5900 },
  { label: "Latvia", code: "LV", value: 6000 },
  { label: "Lebanon", code: "LB", value: 6100 },
  { label: "Liberia", code: "LR", value: 6200 },
  { label: "Libya", code: "LY", value: 6300 },
  { label: "Lithuania", code: "LT", value: 6400 },
  { label: "Luxembourg", code: "LU", value: 6500 },
  { label: "Madagascar", code: "MG", value: 6600 },
  { label: "Malawi", code: "MW", value: 6700 },
  { label: "Malaysia", code: "MY", value: 6800 },
];

export const generateFormattedId = (id, companyCode) => {
  if (id < 0 || id > 9999) {
    throw new Error("ID must be between 0 and 9999 (inclusive).");
  }

  return `${companyCode}-${id.toString().padStart(4, "0")}`;
};
export const revertFormattedId = (formattedId) => {
  const parts = formattedId.split("-");

  if (parts.length !== 2) {
    throw new Error("Invalid formatted ID. It should contain a single hyphen.");
  }

  const idString = parts[1];

  const id = parseInt(idString, 10);

  if (isNaN(id) || id < 0 || id > 9999) {
    throw new Error("Invalid ID value in formatted ID.");
  }

  return id;
};
const msalConfig = {
  auth: {
    clientId: "10608ece-ac47-4208-b5ba-383d88675041",
    authority:
      "https://login.microsoftonline.com/bef898b5-25e5-4796-93ab-11f861b61208",
    // redirectUri: "http://localhost:3000",
    // redirectUri: "https://hermes-test.gamberadynamics.com",
    redirectUri: "https://hermes.gamberadynamics.com",
    // redirectUri: "https://hermes-dev.gamberadynamics.com",
  },
  // Additional configuration as needed
};

const msalInstance = new PublicClientApplication(msalConfig);
export default msalInstance;
