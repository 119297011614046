import React, { useContext, useEffect, useState } from "react";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import AppContext from "./AppContext";
import msalInstance, {
  BASE_FUN_URL,
  BASE_URL,
  CleanKey,
  Roles,
} from "./constants";
import axios from "axios";
import { toast } from "react-toastify";
import { gapi } from "gapi-script";
import {
  AppstoreOutlined,
  BankOutlined,
  BlockOutlined,
  BranchesOutlined,
  CodeOutlined,
  CodeSandboxOutlined,
  FundViewOutlined,
  UserOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Select } from "antd";
import LanguageSwitcher from "./components/LanguageSwitcher";
import { useTranslation } from "react-i18next";

const Layout = ({ children, setNode }) => {
  const { t } = useTranslation();
  const { authCtx } = useContext(AppContext);

  const navList = [
    {
      icon: <FundViewOutlined />,
      label: "Dashboard",
      path: "/",
      children: [],
    },
    {
      icon: <BranchesOutlined />,
      label: "Catalogue",
      path: "/integrationList",
      children: [],
    },
    {
      icon: <CodeOutlined />,
      label: "Log",
      path: "/logList/all/all",
      children: [],
    },

    {
      icon: <AppstoreOutlined />,
      label: "Masters",
      expanded: true,
      children: [
        { icon: <UserOutlined />, label: "User", path: "/userList" },
        { icon: <BlockOutlined />, label: "Application", path: "/appList" },
        authCtx.profile.role === 100
          ? { icon: <BankOutlined />, label: "Company", path: "/companyList" }
          : null,
        authCtx.profile.role === 100
          ? {
              // Conditionally render "Data Simulator" only for role 100
              icon: <CodeSandboxOutlined />,
              label: "Data Simulator",
              path: "/dataSimulator",
              // children: [],
            }
          : null,
      ],
    },
  ];
  const navigate = useNavigate();
  const path = useLocation();
  const [companies, setCompanies] = useState([]);
  const [navState, setNavState] = useState(
    navList.filter((item) => item !== null)
  );
  const [openNav, setNavOpen] = useState(false);
  function handleGoogleLogout() {
    gapi.auth2
      .getAuthInstance()
      .signOut()
      .then(() => {
        console.log("User signed out successfully.");
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  }
  const fetchCompanies = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/Company`);
      const formattedCompanies = response.data.map((company) => ({
        value: company.id,
        label: company.name,
        code: company.code,
      }));
      setCompanies(formattedCompanies);
      authCtx.setCompany(formattedCompanies[0]);
      if (authCtx.profile?.companyId === null) {
        const updatedProfile = {
          ...authCtx.profile,
          company: formattedCompanies[0].label,
          companyId: formattedCompanies[0].value,
          companyCode: formattedCompanies[0].code,
        };

        authCtx.login(updatedProfile);
      }
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };
  const handleDropdownChange = (fieldName) => (selectedOption) => {
    if (selectedOption !== undefined) {
      const selectedCompany = companies.find((x) => x.value === selectedOption);
      const updatedProfile = {
        ...authCtx.profile,
        company: selectedCompany.label,
        companyId: selectedCompany.value,
        companyCode: selectedCompany.code,
      };
      authCtx.login(updatedProfile);
    }
  };

  const firstDiv = document.getElementById("first-div");
  const secondDiv = document.getElementById("second-div"); // Assuming second div has these classes

  function updateWidths() {
    const firstDivWidth = firstDiv?.offsetWidth; // Get actual width in pixels

    let secondDivWidth;
    if (openNav) {
      secondDivWidth = window.innerWidth - firstDivWidth; // Account for padding/margin
    } else {
      secondDivWidth = window.innerWidth - firstDivWidth;
    }
    if (secondDiv) {
      secondDiv.style.width = `${secondDivWidth}px`;
    } else {
      // setNavOpen(!openNav);
      setTimeout(() => {
        setNavOpen(!openNav);
      }, 500);
    } // Set width dynamically
  }
  window.addEventListener("resize", updateWidths);
  document.addEventListener("DOMContentLoaded", updateWidths);
  useEffect(() => {
    // Call updateWidths initially and on window resize
    updateWidths();
  }, [openNav]);
  useEffect(() => {
    if (authCtx.profile.role === 100) {
      fetchCompanies();
    }
  }, []);

  return (
    <div className="flex w-screen h-screen overflow-hidden">
      <div
        id="first-div"
        className={`w-fit transition-all ease-in-out z-50 duration-200 max-w-[300px] shrink-0 bg-blue-950 h-full`}
      >
        <div
          onClick={() => navigate("/")}
          className="h-14 cursor-pointer border-b gap-3 bg-white border-gray-300 flex justify-start px-4 items-center"
        >
          {!openNav && <img src="logo.png" alt="LOGO" className="h-9" />}
          {openNav && (
            <div className="flex gap-2 items-center">
              <img src="logo.png" alt="LOGO" className="h-9" />
              <img
                src="hermes-logo-text.png"
                alt="LOGO"
                className="h-9 invert"
              />
            </div>
          )}
        </div>
        <div
          className={`flex flex-col p-4 pl-2 transition-all ease-in-out duration-200 gap-4 ${
            openNav ? "p-4 pl-2" : "pr-2"
          }`}
        >
          {navState.map((x, index) => (
            <div className="flex flex-col gap-4" key={index}>
              <button
                onClick={() => {
                  if (x.path) navigate(x.path);
                  else
                    setNavState((pre) =>
                      pre.map((y) =>
                        y.label === x.label
                          ? { ...y, expanded: !y.expanded }
                          : y
                      )
                    );
                }}
                className={`flex cursor-pointer gap-2 px-2 rounded-lg items-center justify-between hover:text-slate-200 ${
                  path.pathname == x.path
                    ? "text-white bg-indigo-950 py-1.5 "
                    : "text-gray-400"
                }`}
              >
                <div className="flex gap-2 items-center">
                  {React.cloneElement(x.icon, {
                    style: { fontSize: "20px", color: "#b5c8e6" },
                  })}
                  {/* <img src={x.icon} alt="logo" width={20} className="h-6" /> */}
                  {openNav && (
                    <div className="text-sm w-[170px] text-left transition-all ease-in-out duration-200 font-medium">
                      {t(`layout_items.${CleanKey(x.label)}`)}
                    </div>
                  )}
                </div>
                {x.children.length > 0 && (
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      className={`${x.expanded ? "rotate-180" : ""} w-3 h-3`}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m19.5 8.25-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </div>
                )}
              </button>
              {x.expanded &&
                x.children.map((y) => {
                  if (!y) return null;
                  return (
                    <button
                      onClick={() => {
                        if (y.path) navigate(y.path);
                      }}
                      key={index}
                      className={`flex gap-2 transition-all ease-in-out px-2 rounded-lg duration-200 cursor-pointer items-center justify-between hover:text-slate-200 ${
                        path.pathname == y.path
                          ? "text-white bg-indigo-950 py-1.5 "
                          : "text-gray-400"
                      }  ${openNav ? "ml-5" : ""}`}
                    >
                      <div className="flex gap-2 items-center">
                        {React.cloneElement(y.icon, {
                          style: { fontSize: "20px", color: "#b5c8e6" },
                        })}
                        {/* <img src={y.icon} className="h-6" /> */}
                        {openNav && (
                          <div className="text-sm transition-all ease-in-out duration-200 font-medium">
                            {t(`layout_items.${CleanKey(y.label)}`)}
                          </div>
                        )}
                      </div>
                    </button>
                  );
                })}
            </div>
          ))}
        </div>
        {authCtx.profile.role === 200 && (
          <div className="flex flex-col gap-4">
            <button
              onClick={() =>
                navigate(`companyForm/${authCtx.profile.companyId}`)
              }
              className={`flex cursor-pointer gap-2 px-4 rounded-lg items-center justify-between hover:text-slate-200 ${
                path.pathname == "company"
                  ? "text-white bg-indigo-950 py-1.5 "
                  : "text-gray-400"
              }`}
            >
              <div className="flex gap-2 items-center">
                <SettingOutlined className="text-[20px] text-[#b5c8e6]" />
                {openNav && (
                  <div className="text-sm w-[170px] text-left transition-all ease-in-out duration-200 font-medium">
                    Settings
                  </div>
                )}
              </div>
            </button>
          </div>
        )}
      </div>
      <div
        id="second-div"
        className={`flex flex-col transition-all shrink-0 ease-in-out duration-200
        `}
        style={{
          minWidth: `calc(100% - ${openNav ? "16%" : "4%"})`,
        }}
      >
        <div className="h-14 z-[9999] bg-white shrink-0 flex justify-between items-center border-b border-gray-300 px-5 ">
          <div className="flex items-center gap-4">
            <button type="button" onClick={() => setNavOpen(!openNav)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="w-5 h-5 text-gray-500"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>
            </button>
            <label
              className="font-bold"
              // style={{ fontFamily: "'Chakra Petch', sans-serif" }}
            >
              {/* OBIACTUM INTEGRATION PLATFORM */}
              {authCtx.profile.company}
            </label>
          </div>
          <div className="flex gap-3 items-center">
            {authCtx.profile.role === 100 && (
              <div className="flex gap-2 items-center">
                <p className="text-sm font-medium">Company</p>
                <div className="">
                  <Select
                    className="block w-full  min-w-36 placeholder:text-xsm placeholder:text-gray-300 max-w-lg bg-gray-50 rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500  sm:text-sm"
                    options={companies}
                    value={
                      companies &&
                      companies.find(
                        (option) => option.value === authCtx.profile.companyId
                      )
                    }
                    onChange={handleDropdownChange("company")}
                  />
                </div>
              </div>
            )}
            <LanguageSwitcher />
            <img src="avatar.svg" className="h-7 " />
            <div className="flex flex-col gap-0">
              <label className="font-medium text-slate-600 p-0 text-sm">
                {authCtx?.profile?.name}
              </label>
              <label className="text-xs text-slate-500 p-0">
                {Roles.find((x) => x.value === authCtx?.profile?.role)?.label}
              </label>
            </div>
            <button
              onClick={() => {
                authCtx.logout();
                // msalInstance.logoutPopup();
                handleGoogleLogout();
                navigate("/");
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="h-full w-full max-h-full relative">{children}</div>
      </div>
    </div>
  );
};

export default Layout;
