import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  ConnectionDataSource,
  ConnectorConstraints,
  DataBinding,
  DiagramComponent,
  DiagramConstraints,
  Inject,
  NodeConstraints,
  RadialTree,
  SnapConstraints,
  SymmetricLayout,
} from "@syncfusion/ej2-react-diagrams";
import axios from "axios";
import { BASE_URL, generateFormattedId } from "../../constants";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { DataManager } from "@syncfusion/ej2-data";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";
import myComponent from "./PopupCard";

// creating the connection between the layout nodes and connectors.
function ConnectNodes(parentNode, childNode, arrowDest) {
  let connector = {
    id: parentNode.id + childNode.id,
    sourceID: parentNode.id,
    targetID: childNode.id,
    [arrowDest === "source" ? "sourceDecorator" : "targetDecorator"]: {
      shape: "Arrow",
      style: {
        fill:
          arrowDest === "target"
            ? "#BF40BF"
            : arrowDest === "source"
            ? "#4CBB17"
            : "#6BA5D7",
        strokeColor:
          arrowDest === "target"
            ? "#BF40BF"
            : arrowDest === "source"
            ? "#4CBB17"
            : "#6BA5D7",
      },
    },
    [arrowDest === "target" || arrowDest === "middleware"
      ? "sourceDecorator"
      : "targetDecorator"]: {
      shape: "none",
    },
    constraints: !ConnectorConstraints.Drag & !ConnectorConstraints.Interaction,
    style: {
      fill:
        arrowDest === "target"
          ? "#BF40BF"
          : arrowDest === "source"
          ? "#4CBB17"
          : "#6BA5D7",
      strokeColor:
        arrowDest === "target"
          ? "#BF40BF"
          : arrowDest === "source"
          ? "#4CBB17"
          : "#6BA5D7",
      strokeWidth: 2,
    },
  };
  return connector;
}

function getContent(nodeData, type, appList) {
  return myComponent(nodeData, type, appList);
}

// creating the layout nodes as rectangle in shape.
function GetRectangle(id, type, content, data, appList) {
  let shape = {
    type: "Basic",
    shape: "Ellipse",
  };
  let expandCollapse = {
    expandIcon: {
      shape: "Minus",
      width: 14,
      height: 14,
    },
    collapseIcon: {
      shape: "Plus",
      width: 14,
      height: 14,
    },
  };
  let tooltip = {
    constraints:
      (NodeConstraints.Tooltip,
      ~NodeConstraints.Drag,
      ~NodeConstraints.Select,
      NodeConstraints.HideThumbs) | NodeConstraints.Default,
    //Defines mouse over tooltip for a node
    tooltip: {
      //Sets the content of the Tooltip
      content:
        type !== "ip" && type !== "middleware"
          ? getContent(data, type, appList)
          : "<div></div>",
      //Sets the position of the Tooltip
      position: "BottomRight",
      //Sets the tooltip position relative to the node
      relativeMode: "Object",
    },
  };
  if (type === "middleware") tooltip = { ...tooltip, ...expandCollapse };
  let node = {
    id: id,
    height: type === "ip" ? 250 : type === "middleware" ? 150 : 100,
    width: type === "ip" ? 250 : type === "middleware" ? 150 : 100,
    borderColor: "white",
    type,
    node: data,
    borderColor: "#FFFFFF",
    style: {
      fill: type === "ip" ? "#0047AB" : "#023020",
      strokeColor: type === "ip" ? "#0047AB" : "#023020",
      strokeWidth: 1,
      //   opacity: 0.6,
    },
    shape: shape,

    annotations: [
      {
        content: content,
        style: {
          color: "white",
          bold: true,
          fontSize: type === "ip" ? 40 : type === "middleware" ? 18 : 14,
        },
      },
    ],
  };
  return { ...node, ...tooltip };
}

// creating the symmetrical layout child elements hierarchy.

//sets the layout child elements
// populateNodes();

function SyncfusionFlow() {
  let diagramRef = useRef();
  const navigate = useNavigate();
  const [nodes, setNodes] = useState([]);
  const [connectors, setConnectors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [layers, setLayers] = useState([]);

  let snapSettings = {
    constraints: SnapConstraints.None,
  };

  const loadLookupData = async () => {
    const res = await axios.get(`${BASE_URL}/api/App`);
    if ((res.status = 200)) {
      return res.data;
    }
  };

  const loadData = async (appList) => {
    const res = await axios.get(`${BASE_URL}/api/IntegrationItems`);
    if (res.status === 200) {
      let data = res.data;
      const finalNodes = [];
      const finalConnectors = [];
      const finalLayers = [];
      const parentRect = GetRectangle("ip", "ip", "ESB");
      finalNodes.push(parentRect);
      data.forEach((el) => {
        let childRect_i = GetRectangle(
          "middleware-" + el.id,
          "middleware",
          generateFormattedId(el.id),
          el
        );
        finalNodes.push(childRect_i);
        const layer = {
          id: childRect_i.id,
          visible: false,
          objects: [],
        };
        finalConnectors.push(
          ConnectNodes(parentRect, childRect_i, "middleware")
        );
        let sourceNode = GetRectangle(
          el.id + "-source-" + el.source.id,
          "source",
          el.source.name,
          el.source,
          appList
        );
        layer.objects.push(sourceNode.id);
        finalNodes.push(sourceNode);
        finalConnectors.push(ConnectNodes(childRect_i, sourceNode, "source"));
        el.destinations.forEach((des, index) => {
          let destNode = GetRectangle(
            el.id + "-dest-" + des.id,
            "target",
            des.name,
            des,
            appList
          );
          layer.objects.push(destNode.id);
          finalNodes.push(destNode);
          finalConnectors.push(ConnectNodes(childRect_i, destNode, "target"));
        });
        finalLayers.push(layer);
      });
      console.log(finalNodes);
      setNodes(finalNodes);
      setConnectors(finalConnectors);
      setLayers(finalLayers);
    }
  };

  useEffect(() => {
    const loadAppData = async () => {
      setLoading(true);
      const appData = await loadLookupData();
      loadData(appData);
      setLoading(false);
    };
    loadAppData();
  }, []);

  useEffect(() => {
    if (diagramRef && diagramRef.refresh) {
      diagramRef.refresh();
    }
  }, [nodes, connectors]);

  return (
    <div className="w-full relative h-full grow-0 overflow-hidden">
      {/* {nodes.length > 0 && ( */}
      <div className="absolute top-4 left-5"></div>
      <Spin
        spinning={loading}
        size="large"
        tip="Loading..."
        className="z-50 absolute top-1/2 left-1/2 text-blue-500 -translate-x-1/2 -translate-y-1/2"
      />
      {nodes.length !== 0 ? (
        <DiagramComponent
          id="container"
          width={"100%"}
          height={"100%"}
          style={{ overflow: "hidden" }}
          className="overflow-hidden"
          ref={(diagram) => (diagramRef = diagram)}
          onClick={(e) => e.preventDefault()}
          doubleClick={(e) => {
            console.log(e);

            if (e.type === "middleware")
              navigate(`/logsList/${e.source.node.id}`);
          }}
          layout={{
            //sets the layout as symmetric layout
            type: "RadialTree",
            // root: "parent",
            springLength: 2,
            connectionPointOrigin: "DifferentPoint",
            springFactor: 0.3,
            maxIteration: 500,
            horizontalSpacing: 10,
            verticalSpacing: 10,
            margin: {
              left: 71,
              top: 70,
            },
          }}
          created={() => {
            // add the layers to the existing diagram layer collection
            for (var i = 0; i < diagramRef.nodes.length; i++) {
              if (diagramRef.nodes[i].type === "middleware") {
                diagramRef.nodes[i].isExpanded = false;
              }
            }
            diagramRef.fitToPage({
              mode: "Page",
              region: "PageSettings",
              canZoomIn: true,
              margin: { bottom: 10, top: 10, left: 10, right: 10 },
            });
          }}
          snapSettings={snapSettings}
          nodes={nodes}
          connectors={connectors}
          // dataSourceSettings={{
          //   id: "id",
          //   // parentId: 'Category',
          //   dataManager: new DataManager(nodes),
          //   connectionDataSource: connectors,
          //   //binds the external data with node
          //   doBinding: (nodeModel, data, diagram) => {
          //     nodeModel.annotations = [
          //       {
          //         /* tslint:disable:no-string-literal */
          //         content: data["name"],
          //         margin: {
          //           top: 10,
          //           left: 10,
          //           right: 10,
          //           bottom: 0,
          //         },
          //         style: {
          //           color: "black",
          //         },
          //       },
          //     ];
          //     /* tslint:disable:no-string-literal */
          //     nodeModel.style = {
          //       fill: "#ffeec7",
          //       strokeColor: "#f5d897",
          //       strokeWidth: 1,
          //     };
          //   },
          // }}

          getNodeDefaults={(obj, diagram) => {
            obj.borderColor = "white";
            obj.borderWidth = 1;
            return obj;
          }}
          getConnectorDefaults={(connector, diagram) => {
            //   connector.style = {
            //     strokeColor: "#6BA5D7",
            //     strokeWidth: 2,
            //   };
            // connector.targetDecorator.style.fill = "#6BA5D7";
            // connector.targetDecorator.style.strokeColor = "#6BA5D7";
            return connector;
          }}
          constraints={DiagramConstraints.Default | DiagramConstraints.Tooltip}
          //fit the diagram to the page with respect to mode and region
        >
          <Inject services={[RadialTree, DataBinding]} />
        </DiagramComponent>
      ) : null}
      {/* )} */}
    </div>
  );
}

export default SyncfusionFlow;

// const loadData = async () => {
//   const res = await axios.get(`${BASE_URL}/api/IntegrationItems`);
//   if (res.status === 200) {
//     let data = res.data;
//     const finalData = [nodes[0]];
//     const finalDatac = [];
//     data.forEach((el) => {
//       const rdata = { id: el.id, width: 120, height: 50 };
//       const cdata = {
//         id: el.id + "connector",
//         sourceID: "shape1",
//         targetID: el.id,
//         type: "Straight",
//       };
//       finalData.push(rdata);
//       finalDatac.push(cdata);
//     });
//     setLoading(finalData);
//     setApps(finalDatac);
//   }
// };
